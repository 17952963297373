<template>
  <s-drawer
    v-model:visible="addrShow"
    type="full"
    direction="rtl"
    append-to-body
    no-header
    immediately-render
    :close-on-click-modal="false"
  >
    <div class="j-address-box">
      <s-title-nav
        class="c-address-header"
        :title="language.SHEIN_KEY_PWA_16610"
      >
        <template #prepend>
          <s-title-nav-item
            ref="backBtn"
            is-back-icon
            @click="clickCancel"
          />
        </template>
      </s-title-nav>
      <div class="c-address-content">
        <s-alert
          v-if="noticeContent"
          :type="storeNoticeConfigs?.type"
          :max-rows="storeNoticeConfigs?.maxRows"
          :load-more-txt="language.SHEIN_KEY_PWA_19721 || '&gt;'"
          class="store-address__notice"
          @on-load-more-click="isShownoticeContentDialog=true"
        >
          {{ noticeContent }}
        </s-alert>
        <template v-if="!isStoreConfigContry">
          <div class="address-bottom">
            <input-vue
              :type="'fname'"
              :value="fname"
              :show="keyShow.fname"
              :label="getFieldLabel('fname')"
              :error-show="error.fname.show"
              :error-value="error.fname.value"
              :sensitive-note="sensitiveNote.fname"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
            <input-vue
              :type="'lname'"
              :value="lname"
              :show="keyShow.lname && !isFullnameCountry"
              :label="getFieldLabel('lname')"
              :error-show="error.lname.show"
              :error-value="error.lname.value"
              :sensitive-note="sensitiveNote.lname"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
            <input-vue
              :type="'fatherName'"
              :value="fatherName"
              :show="keyShow.fatherName"
              :label="getFieldLabel('fatherName')"
              :error-show="error.fatherName.show"
              :error-value="error.fatherName.value"
              :sensitive-note="sensitiveNote.fatherName"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
            <input-vue
              :type="'englishName'"
              :value="englishName"
              :show="keyShow.englishName"
              :label="getFieldLabel('englishName')"
              :error-show="error.englishName.show"
              :error-value="error.englishName.value"
              :sensitive-note="sensitiveNote.englishName"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
            <input-vue
              :type="'tel'"
              :value="tel"
              :show="keyShow.tel"
              :label="getFieldLabel('tel')"
              :tel-en="isRussian ? 'RU' : ''"
              :tel-num="isRussian ? '+7' : ''"
              text="Il est necessaire d'indiquer un numéro de téléphone valable, sinon le colis ne peut pas être expédié."
              :error-show="error.tel.show"
              :error-value="error.tel.value"
              :sensitive-note="sensitiveNote.tel"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
            <input-vue
              :type="'taxNumber'"
              :value="taxNumber"
              :show="keyShow.taxNumber"
              :noGap="true"
              :label="getFieldLabel('taxNumber')"
              :error-show="error.taxNumber.show"
              :error-value="error.taxNumber.value"
              :disabled="disabled"
              :language="language"
              @change="inputChange"
              @blur="inputBlur"
              @focus="inputFocus"
              @clear="clearInput"
            />
          </div>

          <div class="address-bottom">
            <div
              ref="storeAddress"
              v-enterkey
              class="address-item"
              :class="{ 'focus-value': storeAddress }"
              tabindex="0"
              @click="clickAddress"
            >
              <label class="item-title">
                <span v-html="'*' + language.SHEIN_KEY_PWA_16616"></span>
              </label>
              <div
                class="item-input"
              >
                <div class="input-area">
                  <input
                    v-model.trim="storeAddress"
                    type="text"
                    class="address-input j-address-storeAddress"
                    placeholder=""
                    readonly
                    tabindex="-1"
                  />
                  <i class="suiiconfont sui_icon_nav_back_24px_mir"></i>
                  <p class="text-placeholer">
                    <span
                      class="title"
                      v-html="'*' + language.SHEIN_KEY_PWA_16616"
                    ></span>
                  </p>
                </div>
              </div>
              <!-- <p
              v-if="error.storeAddress.show"
              class="text-error"
              tabindex="0"
              v-html="language.SHEIN_KEY_PWA_31672"
            ></p> -->
              <p
                v-if="error.storeAddress.show"
                class="text-error"
                tabindex="0"
                v-html="error.storeAddress.value"
              ></p>
            </div>
            <p class="adr-nearest">
              <span
                ref="nearestBtn"
                v-enterkey
                DA-type="syncClick"
                DA-sa-name="nearesttome"
                tabindex="0"
                role="button"
                @click="clickNearestForMe"
                v-html="language.SHEIN_KEY_PWA_16613"
              ></span>
            </p>
          </div>
        </template>
        <template v-else>
          <div
            v-if="storeFormConfigs.length"
            class="address-bottom store__content"
          >
            <div
              v-for="(item, index) in storeFormConfigs"
              :key="item.type + index"
              class="store__item"
            >
              <template v-if="item.type === 'storeAddress'">
                <div
                  ref="storeAddress"
                  v-enterkey
                  class="address-item store-address__item"
                  :class="{ 'focus-value': storeAddress }"
                  tabindex="0"
                >
                  <div class="store__area">
                    <s-input
                      v-model.trim="storeAddress"
                      :placeholder="language?.SHEIN_KEY_PWA_16616 || ''"
                      label=""
                      readonly
                      @click="clickAddress"
                    >
                      <template #prepend>
                        <sui_icon_location_fill_15px size="15px" />
                      </template>
                    </s-input>
                    <sui_icon_more_right_16px_2
                      size="16px"
                      color="#959595"
                      class="store-item__right"
                      @click="clickAddress"
                    />
                  </div>
                  <p
                    v-if="error.storeAddress.show"
                    class="text-error"
                    tabindex="0"
                    v-html="error.storeAddress.value"
                  ></p>
                </div>
                <p class="adr-nearest store__nearest">
                  <span
                    ref="nearestBtn"
                    v-enterkey
                    DA-type="syncClick"
                    DA-sa-name="nearesttome"
                    class="nearest-btn"
                    tabindex="0"
                    role="button"
                    @click="clickNearestForMe"
                    v-html="language.SHEIN_KEY_PWA_16613"
                  ></span>
                </p>
              </template>
              <div
                v-else-if="item.type === 'divide'"
                class="store__divide"
              ></div>
              <calendar-tab
                v-else-if="item.type === 'calendarType'"
                v-model:calendarType="calendarType"
                v-model:idExpireDate="idExpireDate"
                v-model:birthday="birthday"
                :language="language"
              />
              <calendar-date
                v-if="item.isDate"
                v-bind="item"
                :language="language"
                @change="inputChange"
                @blur="inputBlur"
              />
              <input-vue
                v-else
                v-bind="item"
                :language="language"
                @change="inputChange"
                @blur="inputBlur"
                @focus="inputFocus"
                @clear="clearInput"
              />
            </div>
          </div>
        </template>


        <div
          v-if="isRussian"
          class="address-bottom"
        >
          <passport-vue
            ref="passportVue"
            address-type="shopTransit"
            :language="language"
            :operate-type="operateType"
            :disabled="disabled"
            @submit="submitData"
          />
        </div>

        <div
          v-if="keyShow.email"
          class="address-bottom email"
        >
          <input-vue
            :type="'email'"
            :value="email"
            show
            :label="`*${language.SHEIN_KEY_PWA_18384}`"
            :placeholder="language.SHEIN_KEY_PWA_14641"
            :error-show="error.email.show"
            :error-value="error.email.value"
            :disabled="disabled"
            :language="language"
            @change="inputChange"
            @blur="inputBlur"
            @clear="clearInput"
          />
        </div>

        <auxiliary-policy :language="language" />

        <div class="address-save">
          <slot name="bottom">
          </slot>
          <div class="address-save__btn">
            <s-button
              :disabled="isDisabled"
              :loading="isSaveLoading"
              :type="['primary']"
              @click="clickSubmit"
            >
              <span v-html="language.SHEIN_KEY_PWA_15102"></span>
            </s-button>
          </div>
        </div>
      </div>
    </div>
    <map-ru
      v-if="isRussian"
      ref="mapRuVue"
      :abt-test-config="abtTestConfig"
      :addressStoreConfig="addressStoreConfig"
      @select="selectedMap"
    />
    <map-vue
      v-show="!isRussian"
      ref="mapVue"
      :abt-test-config="abtTestConfig"
      :addressStoreConfig="addressStoreConfig"
      :notice-content="noticeContent"
      :is-sa-supersize-merchandise="isSaSupersizeMerchandise"
      @select="selectedMap"
    />
    <SLazyMount>
      <s-dialog
        v-model:visible="confirmModal"
        append-to-body
      >
        <div
          class="mshe-text-center"
          tabindex="0"
          v-html="geolocationConfirmText"
        ></div>
        <template #footer>
          <s-button-group hor>
            <s-button-group-item
              :type="['primary', 'H72PX']"
              @click="clickConfirm"
            >
              {{ language.SHEIN_KEY_PWA_16543 }}
            </s-button-group-item>
            <s-button-group-item
              :type="['H72PX']"
              @click="confirmModal = false"
            >
              {{ language.SHEIN_KEY_PWA_16544 }}
            </s-button-group-item>
          </s-button-group>
        </template>
      </s-dialog>
    </SLazyMount>
    <SLazyMount>
      <s-dialog
        v-model:visible="postionError.show"
        append-to-body
      >
        <div
          class="mshe-text-center"
          tabindex="0"
          v-html="postionError.text"
        ></div>
        <template #footer>
          <s-button
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click="postionError.show = false"
          >
            {{ language.SHEIN_KEY_PWA_15146 }}
          </s-button>
        </template>
      </s-dialog>
    </SLazyMount>
    <SLazyMount>
      <SDialog
        v-model:visible="isShownoticeContentDialog"
        :close-on-click-modal="false"
        append-to-body
      >
        <div
          class="address-notice_contentext"
          v-html="noticeContent"
        ></div>
        <template #footer>
          <SButton
            :type="['primary', 'H72PX']"
            :width="'100%'"
            @click="isShownoticeContentDialog = false"
          >
            {{ language?.SHEIN_KEY_PWA_15146 || '' }}
          </SButton>
        </template>
      </SDialog>
    </SLazyMount>
  </s-drawer>
</template>

<script>
import { defineComponent, nextTick, defineAsyncComponent } from 'vue'
import { enterkey } from 'public/src/pages/common/ada/index.js'
import mapVue from './map'
import mapRu from './map_ru'
import inputVue from '../input.vue'
import { saObj } from '../analysis'
import passportVue from '../passport.vue'
import auxiliaryPolicy from '../child_components/auxiliaryPolicy.vue'
import addressStoreMixins from '../store_mixins.js'
import { parseQueryString, template } from '@shein/common-function'
import schttp from 'public/src/services/schttp'
import orderLogic from 'public/src/pages/common/orderLogic'
import { Icon } from '@shein-aidc/icon-vue3'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'
import { SAlert } from '@shein-aidc/sui-alert/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { STitleNavItem } from '@shein-aidc/sui-title-nav-item/mobile'
import { STitleNav } from '@shein-aidc/sui-title-nav/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SInput } from '@shein-aidc/sui-input/mobile'
import { 
  FNAME_TO_FULLNAME_COUNTRY,
  STORE_CONFIGS_CONTRY,
  SUPPLEMENT_FILED_CHANGE,
  SUPPLEMENT_CONTRY,
  STORE_ADDRESS_DISPLAY,
  NOTICECONTENT_CONFIGS,
  FILL_ADDRESS_FILED_CHANGE 
} from '../config'
import { intersection } from 'lodash'
import { monitorAddress, monitorAddressApi } from 'public/src/pages/components/address/monitor/index.js'
import { sui_icon_location_fill_15px, sui_icon_more_right_16px_2 } from '@shein-aidc/icon-vue3'
import CalendarTab from './CalendarTab.vue'
import { daEventCenter } from '@shein-aidc/basis-sa-event-center'

const { langPath, GOOGLE_LAT_LNG_ACCURACY } = gbCommonInfo

export default defineComponent({
  name: 'StoreVue',
  components: {
    mapVue,
    mapRu,
    inputVue,
    passportVue,
    auxiliaryPolicy,
    Icon,
    STitleNav,
    STitleNavItem,
    SDrawer,
    SLazyMount,
    SButton,
    SAlert,
    SButtonGroupItem,
    SButtonGroup,
    SDialog,
    CalendarTab,
    CalendarDate: defineAsyncComponent(() => import(/* webpackChunkName: "store-address-calendarDate" */ './CalendarDate.vue')),
    sui_icon_location_fill_15px,
    SInput,
    sui_icon_more_right_16px_2
  },
  directives: {
    enterkey,
  },
  mixins: [addressStoreMixins],
  emits: ['save', 'supplement', 'cancel', 'loading'],
  props: {
    countryId: {
      type: [Number, String],
      default: 0
    },
    pageType: {
      type: String,
      default: 'user'
    }
  },
  data() {
    return {
      abtInfo: null,
      pageName: '',
      addrShow: false,
      language: {},
      loadingFlag: false,
      isSaveLoading: false,
      keyShow: {
        fname: true,
        lname: true,
        fatherName: true,
        englishName: true,
        tel: true,
        storeAddress: true,
        standbyTel: false,
        email: false,
        taxNumber: false
      },
      error: {
        fname: {
          value: '',
          show: false
        },
        lname: {
          value: '',
          show: false
        },
        fatherName: {
          value: '',
          show: false
        },
        englishName: {
          value: '',
          show: false
        },
        tel: {
          value: '',
          show: false
        },
        storeAddress: {
          value: '',
          show: false
        },
        email: {
          value: '',
          show: false
        },
        taxNumber: {
          value: '',
          show: false
        },
        standbyTel: {
          value: '',
          show: false,
        },
        nationalId: {
          value: '',
          show: false,
        },
        idExpireDate: {
          value: '',
          show: false,
        },
        birthday: {
          value: '',
          show: false,
        },
        middleName: {
          value: '',
          show: false,
        },
      },
      addrData: {},
      disabled: [],
      fname: '',
      lname: '',
      fatherName: '',
      englishName: '',
      tel: '',
      storeName: '',
      standbyTel: '',
      middleName: '',
      nationalId: '',
      idExpireDate: '',
      calendarType: 1,
      birthday: '',
      // 不含店铺名
      address1: '',
      postcode: '',
      storeId: '',
      storeType: '',
      confirmModal: false,
      operateType: '',
      state: '',
      city: '',
      keyword: '',
      keywordId: '',
      storeRegisterCode: '',
      email: '',
      taxNumber: '',
      postionError: {
        show: false,
        text: ''
      },
      isAccountEmailDone: false,
      isNewLocation: false,
      abtTestConfig: {},
      extraData: {},
      extraParams: {},
      oldCountryId: null,
      noticeContent: '',
      addressStoreConfig: {},
      storeInitData: {},
      transportType: '',
      extraRuleFlag: true,
      isSameTransportType: true,
      // * 沙特超大尺寸商品
      isSaSupersizeMerchandise: false,
      // * 展示公告提示弹窗
      isShownoticeContentDialog: false,
    }
  },
  computed: {
    storeNoticeConfigs(){
      return NOTICECONTENT_CONFIGS?.[this.countryId] ? NOTICECONTENT_CONFIGS?.[this.countryId] : NOTICECONTENT_CONFIGS.default
    },
    storeFormConfigs() {
      const countryObj = {
        186: {
          supplement: [
            { type: 'divide' },
            {
              type: 'fname',
              value: this.fname,
              show: true,
              label: this.getFieldLabel('fname'),
              errorShow: this.error.fname.show,
              errorValue: this.error.fname.value,
              sensitiveNote: this.sensitiveNote.fname,
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'middleName',
              value: this.middleName,
              show: true,
              label: this.getFieldLabel('middleName'),
              errorShow: this.error.middleName.show,
              errorValue: this.error.middleName.value,
              text: this.language?.SHEIN_KEY_PWA_37267 || '',
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'lname',
              value: this.lname,
              show: true,
              label: this.getFieldLabel('lname'),
              errorShow: this.error.lname.show,
              errorValue: this.error.lname.value,
              sensitiveNote: this.sensitiveNote.lname,
              disabled: this.disabled,
              defaultValue: '',
            },
            { type: 'divide' },
            {
              type: 'nationalId',
              value: this.nationalId,
              show: true,
              label: this.getFieldLabel('nationalId'),
              errorShow: this.error.nationalId.show,
              errorValue: this.error.nationalId.value,
              text: this.language?.SHEIN_KEY_PWA_37271 || '',
              isShowHint: true,
              defaultValue: '',
            },
            { type: 'divide' },
            { type: 'calendarType', defaultValue: 1, },
            {
              type: 'idExpireDate',
              value: this.idExpireDate,
              label: this.getFieldLabel('idExpireDate'),
              text: this.language?.SHEIN_KEY_PWA_25073 || '',
              isDate: true,
              errorObj: this.error.idExpireDate,
              calendarType: this.calendarType,
              defaultValue: '',
            },
            {
              type: 'birthday',
              value: this.birthday,
              label: this.getFieldLabel('birthday'),
              text: this.language?.SHEIN_KEY_PWA_25081 || '',
              isDate: true,
              errorObj: this.error.birthday,
              calendarType: this.calendarType,
              defaultValue: '',
            },
          ],
          default: [
            { type: 'divide' },
            { type: 'storeAddress' },
            { type: 'divide' },
            {
              type: 'fname',
              value: this.fname,
              show: true,
              label: this.getFieldLabel('fname'),
              errorShow: this.error.fname.show,
              errorValue: this.error.fname.value,
              sensitiveNote: this.sensitiveNote.fname,
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'middleName',
              value: this.middleName,
              show: true,
              label: this.getFieldLabel('middleName'),
              errorShow: this.error.middleName.show,
              errorValue: this.error.middleName.value,
              text: this.language?.SHEIN_KEY_PWA_37267 || '',
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'lname',
              value: this.lname,
              show: true,
              label: this.getFieldLabel('lname'),
              errorShow: this.error.lname.show,
              errorValue: this.error.lname.value,
              sensitiveNote: this.sensitiveNote.lname,
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'tel',
              value: this.tel,
              show: this.keyShow.tel,
              label: this.getFieldLabel('tel'),
              errorShow: this.error.tel.show,
              errorValue: this.error.tel.value,
              sensitiveNote: this.sensitiveNote.tel,
              disabled: this.disabled,
              defaultValue: '',
            },
            {
              type: 'standbyTel',
              value: this.standbyTel,
              show: true,
              label: this.getFieldLabel('standbyTel'),
              errorShow: this.error.standbyTel.show,
              errorValue: this.error.standbyTel.value,
              sensitiveNote: this.sensitiveNote.standbyTel,
              disabled: this.disabled,
              defaultValue: '',
            },
            { type: 'divide' },
            {
              type: 'nationalId',
              value: this.nationalId,
              show: true,
              label: this.getFieldLabel('nationalId'),
              errorShow: this.error.nationalId.show,
              errorValue: this.error.nationalId.value,
              text: this.language?.SHEIN_KEY_PWA_37271 || '',
              isShowHint: true,
              defaultValue: '',
            },
            { type: 'divide' },
            { type: 'calendarType', defaultValue: 1, },
            {
              type: 'idExpireDate',
              value: this.idExpireDate,
              label: this.getFieldLabel('idExpireDate'),
              text: this.language?.SHEIN_KEY_PWA_25073 || '',
              isDate: true,
              errorObj: this.error.idExpireDate,
              calendarType: this.calendarType,
              defaultValue: '',
            },
            {
              type: 'birthday',
              value: this.birthday,
              label: this.getFieldLabel('birthday'),
              text: this.language?.SHEIN_KEY_PWA_25081 || '',
              isDate: true,
              errorObj: this.error.birthday,
              calendarType: this.calendarType,
              defaultValue: '',
            },
          ],
        },
      }
      return this.operateType === 'supplement'
        ? countryObj?.[this.countryId].supplement || []
        : countryObj?.[this.countryId].default || []
    },
    // * 是否是配置国家
    isStoreConfigContry() {
      return STORE_CONFIGS_CONTRY.includes(Number(this.countryId))
    },
    geolocationConfirmText() {
      const { SHEIN_KEY_PWA_23795, SHEIN_KEY_PWA_23005 } = this.language
      return template(
        `<a target="blank" style="color: #2D68A8;" href="${langPath}/product/article/282">${SHEIN_KEY_PWA_23005}</a>`,
        SHEIN_KEY_PWA_23795
      )
    },
    storeAddress: {
      get() {
        return [this.storeName, this.address1].filter(Boolean).join(' ')
      },
      set() {}
    },
    isDisabled() {
      return !this.isAccountEmailDone || this.isSaveLoading  || !this.isSameTransportType
    },
    storeConfigData() {
      return {
        storeId: this.storeId,
        pageName: this.pageName,
        countryId: this.countryId,
        extraData: this.extraData,
        state: this.state,
        city: this.city,
        storeRegisterCode: this.storeRegisterCode
      }
    },
    isRussian() {
      return this.countryId == 178
    },
    isFullnameCountry() {
      return FNAME_TO_FULLNAME_COUNTRY.includes(Number(this.countryId))
    },
    isSa() {
      return this.countryId == 186
    },
  },
  watch: {
    addrShow(val) {
      if (val) {
        saObj.exposePopupPickupaddressEdit({
          address_from: this.operateType === 'supplement' ? 'user_supplement' : this.pageType === 'order' ? 'order' : 'user',
        })
      }
    },
    googleMapShow(val) {
      if (!val) {
        setTimeout(() => {
          if (this.isNearest) {
            this.$refs.nearestBtn.focus()
          } else {
            this.$refs.storeAddress.focus()
          }
        }, 500)
      }
    }
  },
  methods: {
    // * 初始化数据
    initStoreData() {
      this.sensitiveNote = {
        fname: '',
        lname: '',
        fatherName: '',
        englishName: '',
        tel: '',
        standbyTel: '',
      }
      this.keyShow = {
        fname: true,
        lname: true,
        fatherName: true,
        englishName: true,
        tel: true,
        storeAddress: true,
        standbyTel: false,
        email: false,
        taxNumber: false
      }
    },
    // * 操作初始赋值
    handleOptsData(addrData = {}, extraData = {}) {
      const { isSaSupersizeMerchandise = false } = extraData
      this.isSaSupersizeMerchandise = isSaSupersizeMerchandise
      this.storeFormConfigs.forEach(item => {
        if(!['divide', 'storeAddress'].includes(item.type)){
          const fileName = FILL_ADDRESS_FILED_CHANGE[item.type]
          if(fileName && addrData?.[fileName]){
            this[item.type] = addrData?.[fileName] || item.defaultValue
          }else{
            this[item.type] = addrData?.[item.type] || item.defaultValue
          }          
        }
      })
    },
    async updateUI(opts) {
      this.initStoreData()
      const parmas = {
        countryId: this.countryId,
        pageSource: opts?.extraData?.pageSource,
        billno: opts?.extraData?.billno,
        transport_type: opts.extraData?.mallList?.[0]?.transportType
      }
      // * 沙特国家特殊处理
      if(opts?.type == 'supplement' && this.isSa) {
        parmas.supplementScene = 3
      }

      this.storeInitData =  await this.getStoreInitData(parmas)
      const orderAddress = await this.getOrderAddress({
        billno: opts?.extraData?.riskData?.order_no,
        countryId: this.countryId,
      })
      this.getStoreConfigInfo()
      this.fetchData()
      this.getAddressCheckRule({ type: opts?.type })
      this.getSensitiveCheckRule()
      this.transportType =  opts.extraData?.mallList?.[0]?.transportType || this.storeInitData?.storeOrderInfo?.info?.transport_type
      const {
        show = true,
        type,
        url,
        ajaxType = 'post',
        formData = {},
        pageName,
        disabled,
        extraData = {},
        extraParams = {},
      } = opts
      const addrData = Object.assign(opts?.addrData, orderAddress)
      this.addrShow = show
      this.operateType = type
      this.ajaxUrl = url
      this.ajaxType = ajaxType
      this.formData = formData
      this.addrData = addrData
      this.disabled = disabled
      this.pageName = pageName
      this.filterByCountry()
      this.clearData(['email'])
      this.clearError()
      this.fname = addrData.fname
      this.lname = addrData.lname
      this.fatherName = addrData.fatherName || ''
      this.englishName = addrData.englishName || ''
      this.tel = addrData.tel
      this.state = addrData.state
      this.city = addrData.city
      this.lat = addrData?.latitude || addrData?.lat
      this.lng = addrData?.longitude || addrData?.lng
      this.extraData = extraData
      this.extraParams = extraParams
      this.postcode = addrData.postcode
      this.taxNumber = addrData.taxNumber || ''
      this.noticeContent = extraData?.noticeContent || ''
      this.keyShow.taxNumber = false
      this.fieldRequired.taxNumber = false
      this.storeType = addrData.storeType || ''
      // * 西班牙店配补充地址处理
      const isESSuplement = this.operateType === 'supplement' && this.countryId == 198
      const isShowTax = this.taxNumber && this.countryId == 198
      if (isESSuplement || isShowTax) {
        this.keyShow.taxNumber = true
      }
      if (isESSuplement) {
        this.fieldRequired.taxNumber = true
      }
      this.getAccountEmail()
      this.getDHLInfo()

      if (this.isStoreConfigContry) {
        // * 初始赋值的操作
        this.handleOptsData(addrData, extraData)
        // * keyShow值的处理
        this.storeFormConfigs.forEach(item => {
          if (!['divide', 'calendarType'].includes(item.type)) {
            this.keyShow[item.type] = true
          }
        })
      }

      if (['edit', 'supplement'].includes(type)) {
        const addressList =  STORE_ADDRESS_DISPLAY[this.countryId] ? STORE_ADDRESS_DISPLAY?.[this.countryId] || [] : STORE_ADDRESS_DISPLAY?.default
        this.address1 = addressList.map((type) => addrData[type]).filter(Boolean).join(' ').trim()
        this.storeName = addrData.storeName
        this.storeId = addrData.storeId
        nextTick(() => {
          this.checkBeforeSubmit()
          this.$refs?.passportVue?.setData(addrData)
          // 风控初始化
          if(this.isNeedRiskControl){
            this.ininRiskControl()
          }
        })
        if(addrData?.transportDiffAddress == '1') {
          this.handleError('storeAddress', true, this.language.SHEIN_KEY_PWA_31586)
          this.extraRuleFlag = false
          this.isSameTransportType = false
        }
      } else if (this.addressSensitiveCheck) {
        nextTick(() => {
          for (let i in this.keyShow) {
            if (this.keyShow[i]) {
              if (typeof this.addressSensitiveCheck?.[i] === 'function') {
                this.addressSensitiveCheck[i]()
              }
            }
          }
        })
      }
      
      if(this.isSaSupersizeMerchandise){
        // * 沙特国家超大尺寸商品处理
        this.$nextTick(() => {
          this.clickAddress({ saSupersizeMerchandiseText: this.storeAddress || '' })
        })
      }

      monitorAddress({
        metric_name: 'web_customer_address_open_address_end_total',
        tags: {
          ...this.commonAddressMonitorTags,
          result: '1'
        }
      })    
    },
    handleError(field, show, error){
      if(!(this.error?.[field] != null)) return
      this.error[field].value = error
      this.error[field].show = show
    },
    async getStoreInitData(parmas){
      const res = await schttp({
        method: 'POST',
        url: '/api/user/addressbook/getStoreInitData/get',
        data: parmas
      })
      monitorAddressApi({
        apiPath: '/api/user/addressbook/getStoreInitData/get',
        response: res,
        params: parmas
      })
      return res
    },
    async getDHLInfo() {
      // Germany
      if (this.countryId != 82) {
        return
      }
      let dhlInfo = this.addrData
      const { billno } = this.extraData || {}
      if (billno) {
        const { shipping } = this.queryAddressInfo(billno)
        dhlInfo = shipping || {}
      }
      const {
        keyword = '',
        keywordId = '',
        storeRegisterCode = '',
        register_code = '',
        keyword_id = ''
      } = dhlInfo
      this.keyword = keyword
      this.keywordId = keywordId || keyword_id
      this.storeRegisterCode = storeRegisterCode || register_code
    },
    queryAddressInfo() {
      try {
        const res = this.storeInitData?.storeOrderInfo
        return res?.info || {}
      } catch (e) {
        return {}
      }
    },
    async getStoreConfigInfo() {
      if (!this.abtInfo) {
        // eslint-disable-next-line @shein-aidc/abt/abt
        this.abtInfo = this.storeInitData?.storeAbt?.params
        this.isNewLocation = this.abtInfo?.NewLocation?.param === 'NewLocation=on'
      }
      const configFlag = `ShowPickUpNew${this.countryId}`
      const showPickUpNewConfig = this.abtInfo?.ShowPickUpNew?.param || {}
      let abtChannel = showPickUpNewConfig[configFlag]
        ? parseQueryString(showPickUpNewConfig[configFlag])?.channel || []
        : parseQueryString(this.abtInfo?.ShowPickUp?.param)?.channel || []

      const res = this.storeInitData?.storeConfigInfo
      if(res?.code == 0 && res?.info){
        const { store_extra = {}, store_info_list = [] } = res?.info || {}
        const tempList = store_info_list?.map(item => item.store_channel_type) || []
        abtChannel = typeof abtChannel === 'string' ? JSON.parse(abtChannel) : abtChannel
        const abtTestConfig = {
          search_home: store_extra?.home_address || 'off',
          google: store_extra?.google_association || 'off',
          ShowLogo: store_extra?.display_logo || 'off',
          distance: store_extra?.display_distance || 'off',
          pic: store_extra?.display_store_pic || 'off',
          ShowType: store_extra?.display_store_location || 'off',
          logicts: store_extra?.display_filter_box || 'off',
          channel: abtChannel.length ? intersection(tempList, abtChannel) : tempList,
        }
        this.addressStoreConfig = {
          ...res.info,
          abtTestConfig,
        }
        this.abtTestConfig = abtTestConfig
      } else {
        const abtTestConfig = {
          search_home: 'off',
          google: 'off',
          ShowLogo: 'off',
          distance: 'off',
          pic: 'off',
          ShowType: 'off',
          logicts: 'off',
          channel: [],
        }
        this.addressStoreConfig = {
          store_config: {
            distance: 0,
            sort_type: 0
          },
          abtTestConfig,
          store_info_list: []
        }
        this.abtTestConfig = abtTestConfig
      }
    },
    fetchData() {
      const data = this.storeInitData?.storeLanguage
      this.language = (data && data.language) || {}
    },
    clearEmailData() {
      this.isAccountEmailDone = false
      this.email = ''
      this.keyShow.email = false
    },
    getAccountEmail() {
      this.clearEmailData()
      this.getAccountAndOrderEmail()
      if (this.operateType !== 'add') {
        nextTick(() => {
          this.addressCheck?.email()
        })
      }
      this.isAccountEmailDone = true
    },
    getAccountAndOrderEmail() {
      this.email = ''
      this.keyShow.email = false
      const res = this.storeInitData?.storeAccountAndOrderEmail
      const { info: { order_email, register_email } = {} } = res || {}
      this.keyShow.email = !register_email
      this.email = order_email
    },
    filterByCountry() {
      this.keyShow.englishName = this.isRussian
      this.keyShow.fatherName = this.isRussian
    },
    clearError() {
      for (var i in this.error) {
        this.error[i].show = false
      }
    },
    clearData: function (extra = []) {
      for (const i in this.keyShow) {
        if (this[i] && extra.indexOf(i) == -1) {
          this[i] = ''
        }
      }
    },
    inputChange(type, value) {
      this[type] = value
    },
    inputFocus(type) {
      if (this.sensitiveNote) {
        this.sensitiveNote[type] = ''
      }
    },
    inputBlur(type) {
      if (this.isStoreConfigContry) {
        const list = [
          'email',
          ...(this.storeFormConfigs.map(item => item.type) || []),
        ]
        if (list.includes(type)) {
          this.addressCheck?.[type]?.()
        }
        this.addressSensitiveCheck?.[type]?.()
      } else {
        this.addressCheck?.[type]?.()
        this.addressSensitiveCheck?.[type]?.()
      }
    },
    clearInput(type) {
      this[type] = ''
    },
    checkBeforeSubmit() {
      let flag = true
      for (let i in this.keyShow) {
        if (this.keyShow[i]) {
          // * 沙特补充地址规则特殊处理
          const isSaSpecialHandle = this.operateType == 'supplement' && this.isSa && ['fatherName', 'englishName', 'tel', 'storeAddress', 'standbyTel'].includes(i)
          if (typeof this.addressCheck[i] === 'function' && !isSaSpecialHandle) {
            flag = this.addressCheck[i]() && flag
          }
          if (typeof this.addressSensitiveCheck?.[i] === 'function') {
            this.addressSensitiveCheck[i]()
          }
        }
      }
      return flag
    },
    getData() {
      let data = {
        countryId: this.countryId,
        fname: this.fname,
        lname: this.lname,
        tel: this.tel,
        postcode: this.postcode,
        storeId: this.storeId,
        storeType: this.storeType,
        keyword: this.keyword,
        keywordId: this.keywordId,
        keyword_id: this.keywordId,
        register_code: this.storeRegisterCode,
        storeRegisterCode: this.storeRegisterCode,
        transportCode: this.transportType
      }
      if (this.countryId == 178) {
        data = {
          ...data,
          father_name: this.fatherName,
          english_name: this.englishName
        }
        if (this.$refs?.passportVue) {
          const passportVue = this.$refs.passportVue || {}
          data = {
            ...data,
            passport_number: passportVue.getPassportNumber(),
            passport_issue_place: passportVue.issuePlaceValue || '',
            passport_issue_date: passportVue.issueDateValue || '',
            tax_number: passportVue.taxNumberValue || ''
          }
        }
      }
      if (this.countryId == 198) {
        const taxName = this.pageType === 'order' ? 'tax_number' : 'taxNumber'
        data[taxName] = this.taxNumber
      }
      if (this.keyShow.email) {
        data.order_email = this.email
      }
      if (this.riskIdForKafka) {
        data.riskIdForKafka = this.riskIdForKafka
        this.riskIdForKafka = ''
      }
      // * 配置化国家数据处理
      if (this.isStoreConfigContry) {
        this.storeFormConfigs.forEach(item => {
          if (
            !['divide', 'storeAddress'].includes(item.type) &&
            this[item.type]
          ) {
            data[item.type] = this[item.type]
          }
        })
      }
      return data
    },
    async supplemenHandle() {
      this.$emit('loading', true)
      let data = {
        country_id: this.countryId,
        store_id: this.storeId,
        store_type: this.storeType,
        distribution_type: 2,
      }
      // * 配置化国家数据处理
      if (this.isStoreConfigContry) {
        this.storeFormConfigs.forEach(item => {
          if (!['divide'].includes(item.type) && this[item.type]) {
            const filedName = SUPPLEMENT_FILED_CHANGE[item.type]
            if (filedName) {
              data[filedName] = this[item.type]
            } else {
              data[item.type] = this[item.type]
            }
          }
        })
      } else {
        data = {
          ...data,
          fname: this.fname,
          lname: this.lname,
          tax_number: this.taxNumber,
          tel: this.tel,
        }
      }
      try {
        const res = await schttp({
          method: 'POST',
          url: '/api/user/addressbook/extraAddress/update',
          data
        })
        this.$emit('loading', false)
        const { code } = res || {}
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: code == '0' ? '1' : '0',
            reason: code == '0' ? 'pass' : 'requestError'
          },
          params: {
            code: code,
            api_path: '/api/user/addressbook/extraAddress/update'
          }
        })
        monitorAddressApi({
          apiPath: '/api/user/addressbook/extraAddress/update',
          response: res,
          params: data
        })
        if (code == 0) {
          this.addrShow = false
          res.formData =  {
            ...this.addrData,
            ...this.getData(),
            storeName: this.storeName,
            address1: this.address1,
            city: this.city,
            state: this.state,
          }

        }
        saObj.saveStoreAddress({
          result: code == 0 ? 1 : 2,
          address_from: 'user_supplement',
        })
        this.$emit('supplement', res)
      } catch (error) {
        console.error(error)
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: '0',
            reason: 'serviceError'
          }
        })
      }
    },
    async postSubmitData() {
      this.$emit('loading', true)
      let data = this.getData()
      try {
        const params = this.ajaxUrl?.toUpperCase() === 'GET'
          ? $.extend(data, this.formData, this.extraParams)
          : {}
        const res = await schttp({
          method: this.ajaxType,
          url: this.ajaxUrl,
          data: $.extend(data, this.formData, this.extraParams),
          params
        })
        this.$emit('loading', false)
        const { code, tips } = res || {}
        saObj.saveStoreAddress({
          result: code == 0 ? 1 : 2,
          address_from: this.pageType === 'order' ? 'order' : 'user',
        })
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: code == '0' ? '1' : '0',
            reason: code == '0' ? 'pass' : 'requestError'
          },
          params: {
            code: code,
            api_path: this.ajaxUrl
          }
        })
        monitorAddressApi({
          apiPath: this.ajaxUrl,
          response: res,
          params
        })
        if (code == 0) {
          if (!res?.info?.fail_info_list?.length) {
            // 存在fail_info_list 不需要主动关闭弹窗
            this.addrShow = false
          }
          res.formData = $.extend(data, {
            storeName: this.storeName,
            address1: this.address1,
            city: this.city,
            state: this.state
          })
        } else if (code == 401987) {
          SToast(tips)
        } else if (code == 402001) {
          SToast(this.language.SHEIN_KEY_PWA_31586)
          this.handleError('storeAddress', true, this.language.SHEIN_KEY_PWA_31586)
          this.extraRuleFlag = false
          this.isSameTransportType = false
          return
        }
       
        this.$emit('save', res)
      } catch (error) {
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: '0',
            reason: 'serviceError'
          }
        })
        console.log(error)
      }
    },
    async submitData() {
      if (SUPPLEMENT_CONTRY.includes(Number(this.countryId)) && this.operateType === 'supplement') {
        this.supplemenHandle()
        return
      }
      let { editAddressFlag } = this.addrData || {}
      let data = this.getData()
      if (editAddressFlag && !orderLogic.hasDiffParamsValue(this.addrData, data)) {
        this.addrShow = false
        this.$emit('save', {
          code: 0,
          formData: $.extend(this.addrData, data)
        })
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: '1',
            reason: 'noDiffParamsValue'
          }
        })
        return false
      }
      const riskFlag = await this.handleRiskControl()
      if(!riskFlag){
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: '0',
            reason: 'riskControl'
          }
        })
        return
      }
      await this.postSubmitData()

    },
    clickSubmit() {
      let flag = this.checkBeforeSubmit()
      let passportFlag = true
      if (this.$refs?.passportVue) {
        passportFlag = this.$refs.passportVue.checkBeforSubmit(flag)
        flag = flag && passportFlag
      }
      if (flag) {
        this.submitData()
      } else {
        monitorAddress({
          metric_name: 'web_customer_address_click_save_total',
          tags: {
            ...this.commonAddressMonitorTags,
            result: '0',
            reason: passportFlag ? 'regexp' : 'passportValid'
          }
        })
      }
    },
    clickNearestForMe() {
      this.isNearest = true
      this.confirmModal = true
    },
    selectedMap({
      storeName = '',
      address = '',
      postcode = '',
      storeId = '',
      state = '',
      city = '',
      Latitude,
      Longitude,
      storeType = '',
      keyword = '',
      keywordId = '',
      storeRegisterCode = ''
    }) {
      this.storeName = storeName
      this.address1 = address
      this.postcode = postcode
      this.storeId = storeId
      this.state = state
      this.city = city
      this.lat = Latitude
      this.lng = Longitude
      this.storeType = storeType
      this.keyword = keyword
      this.keywordId = keywordId
      this.storeRegisterCode = storeRegisterCode
      const isPassTranspost = this.addressStoreConfig?.store_info_list?.some(i=>i.store_channel_type == storeType)
      if(isPassTranspost) {
        this.isSameTransportType = true
        this.extraRuleFlag = true
      }
      this.addressCheck['storeAddress']()
    },
    clickAddress({ saSupersizeMerchandiseText = '' } = {}) {
      daEventCenter.triggerNotice({
        daId: 'click_pickupaddress_rightarrow:simple',
      })
      this.isNearest = false
      if (this.operateType === 'add' && this.isNewLocation && !this.storeAddress) {
        this.toMap()
        return
      }
      nextTick(() => {
        if (this.isRussian) {
          this.$refs.mapRuVue.initData({
            postcode: this.postcode,
            transport_type: this.transportType,
            ...this.storeConfigData,
          })
          return
        }
        this.$refs.mapVue.initData({
          postcode: this.postcode,
          transport_type: this.transportType,
          ...this.storeConfigData,
        })
        if (this.abtTestConfig.google === 'on' && this.lat && this.lng) {
          this.$refs.mapVue.clickSearch({ lat: this.lat, lng: this.lng, saSupersizeMerchandiseText })
        } else {
          // * 沙特国家特殊操作
          if(this.isSa) {
            this.$refs.mapVue.clickSearch({ saSupersizeMerchandiseText, lat: this.lat, lng: this.lng, })
          }else if(this.postcode) {
            this.$refs.mapVue.clickSearch()
          }
        }
      })
    },
    async toMap() {
      const { type = '', data = {} } = await this.getCurrentPosition()
      let params = {
        postcode: this.postcode,
        ...this.storeConfigData
      }
      let lat = ''
      let lng = ''
      if (type === 'success') {
        let location = window.tempLoc || data
        const accuracy = GOOGLE_LAT_LNG_ACCURACY?.[this.countryId] || 4
        lat = location.lat.toFixed(accuracy)
        lng = location.lng.toFixed(accuracy)
        params = {
          ...params,
          lat,
          lng
        }
      }
      nextTick(() => {
        if (this.isRussian) {
          this.$refs.mapRuVue.initData(params)
          return
        }
        this.$refs.mapVue.initData(params)
        if (lat && lng) {
          this.$refs.mapVue.getMrInfo({ lat, lng })
        }
      })
    },
    getCurrentPosition() {
      // eslint-disable-next-line no-unused-vars
      return new Promise((resolve, reject) => {
        if (!navigator.geolocation) {
          return resolve({ type: 'notSupport' })
        }
        navigator.geolocation.getCurrentPosition(
          ({ coords: { latitude = '', longitude = '' } = {} } = {}) => {
            resolve({ type: 'success', data: { lat: latitude, lng: longitude } })
          },
          ({ code = '', PERMISSION_DENIED = '' } = {}) => {
            resolve({ type: 'failed', data: { code, PERMISSION_DENIED } })
          }
        )
      })
    },
    getNearestData({ lat = '', lng = '' }) {
      if (this.isRussian) {
        this.$refs.mapRuVue.nearestForMe({
          lat,
          lng,
          ...this.storeConfigData
        })
        return
      }
      this.$refs.mapVue.initData(this.storeConfigData)
      this.$refs.mapVue.nearestForMe({
        lat,
        lng,
        ...this.storeConfigData
      })
    },
    async clickConfirm() {
      this.confirmModal = false
      const { type = '', data = {} } = await this.getCurrentPosition()
      if (type === 'notSupport') {
        return
      }
      nextTick(() => {
        if (type === 'success') {
          const location = window.tempLoc || data
          let lat = location.lat.toFixed(7)
          let lng = location.lng.toFixed(7)
          lat.length == 9 && (lat = '0' + lat)
          lng.length == 9 && (lng = '0' + lng)
          this.getNearestData({ lat, lng })
        } else {
          this.getNearestData({})
          setTimeout(() => {
            const { code = '', PERMISSION_DENIED = '' } = data
            this.postionError = {
              show: true,
              text:
                code === PERMISSION_DENIED
                  ? this.language.SHEIN_KEY_PWA_18017
                  : this.language.SHEIN_KEY_PWA_18018
            }
          }, 300)
        }
      })
    },
    clickCancel() {
      this.addrShow = false
      this.$emit('cancel')
    }
  },
})
</script>
<style lang="less">
.store-address__notice {
  border-top: 0.32rem solid #f6f6f6;
  .sui-alert .sui-alert__description {
    word-break: break-all;
  }
}
</style>
<style lang="less" scoped>
.address-item {
  .store__area {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid @sui_color_gray_weak1;
    .store-item__right {
      margin-bottom: 24 / 75rem;
      margin-left: 8 / 75rem;
    }
    :deep(.sui-input__field) {
      color: @sui_color_brand;
    }
    :deep(.sui-input) {
      flex: 1;
      &::after {
        display: none;
      }
      .sui-input__left {
        padding: 0 0 32 / 75rem 0;
      }
    }
    :deep(.sui-input__label) {
      display: none;
    }
    :deep(.sui-input__left_is-two-stage) {
      align-items: center;
    }
    :deep(.sui-input__two-stage-prev) {
      margin-top: 0;
      padding-right: 0;
      margin-right: 8 / 75rem;
      border-right: none;
      line-height: 1;
    }
    :deep(.sui-input__box) {
      margin-top: 0;
    }
  }
}
.j-address-box .c-address-content .store__content {
  border-top: 0;
  .store__item {
    width: 100%;
    .store__divide {
      width: 100%;
      height: 20 / 75rem;
      background-color: #f6f6f6;
    }
  }
}
.j-address-box .c-address-content .address-bottom .store__nearest .nearest-btn {
  text-decoration: none;
}
.address-notice_contentext {
  word-break: break-word;
  text-align: left;
}

.j-address-box .c-address-content .address-bottom .store__item  .store-address__item {
  margin-top: 32 / 75rem;
}

</style>

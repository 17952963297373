<template>
  <div class="j-address-vue">
    <template v-if="countries.includes(+countryId)">
      <store-tw-vue
        v-if="+countryId === 209"
        ref="storeTwVue"
        :page-type="pageType"
        @privacy="privacyShow = true"
        @loading="loading"
        @save="submitClick"
        @cancel="$emit('cancel')"
      >
        <template #bottom>
          <slot name="bottom">
          </slot>
        </template>
      </store-tw-vue>
      <store-vue
        v-else
        ref="storeFrVue"
        :country-id="+countryId"
        :page-type="pageType"
        @privacy="privacyShow = true"
        @loading="loading"
        @save="submitClick"
        @cancel="$emit('cancel')"
        @supplement="supplement"
      >
        <template #bottom>
          <slot name="bottom">
          </slot>
        </template>
      </store-vue>
    </template>

    <!-- 加载中 -->
    <SLazyMount mount-prop-name="show">
      <s-loading
        type="curpage"
        :show="loadingFlag"
        mask-transparent
      />
    </SLazyMount>

    <!-- 添加成功或失败 -->
    <SLazyMount>
      <s-dialog
        v-model:visible="resultsShow"
        append-to-body
        class="address-tips"
      >
        <i
          class="iconfont"
          :class="[resultsFlag ? 'icon-success' : 'icon-fail']"
        ></i>
        <div
          class="mshe-text-center"
          tabindex="0"
        >
          {{ resultsText }}
        </div>
      </s-dialog>
    </SLazyMount>
  </div>
</template>

<script>
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { SLazyMount } from '@shein-aidc/sui-lazy-mount/mobile'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { defineComponent, nextTick } from 'vue'
import storeTwVue from './vue_tpls/store_tw'
import storeVue from './vue_tpls/store'
import { monitorAddress } from 'public/src/pages/components/address/monitor/index.js'

const countries = gbCommonInfo?.SHOP_TRANSIT?.countries || []

export default defineComponent({
  components: {
    storeTwVue,
    storeVue,
    SLazyMount,
    SLoading,
    SDialog,
  },
  emits: ['save', 'supplement', 'cancel'],
  props: {
    pageType: {
      type: String,
      default: 'user',
    },
  },
  data () {
    return {
      countries,
      countryId: 0,
      loadingFlag: false,
      resultsShow: false,
      resultsFlag: true,
      resultsText: '',
      privacyShow: false,
      privacyTitle: '隱私政策'
    }
  },
  methods: {
    /**
    * 对外提供的方法,展示弹窗店配组件
    * @param {object} config
    * @param config.show 弹框是否展示，默认值：true (非必填)
    * @param config.type 操作类型：add/edit (必填)
    * @param config.addrData 编辑时地址详情 (非必填)
    * @param config.countryId 国家或地区ID，默认值：209 (非必填)
    * @param config.url ajax请求链接 (必填)
    * @param config.ajaxType ajax请求类型,默认值：get (非必填)
    * @param config.formData ajax请求参数 (非必填)
    * @param config.pageName 页面类型：下单页/二次下单页/订单详情页 (非必填)
    * @param config.extra 需隐藏的字段 (非必填)
    * @param config.disabled 不可更改的字段 (非必填)
    * 例子:
    *
    * updateUI({ show: true, type: 'add', countryId: 209, url: '')
    */
    updateUI (data) {
      const opts = typeof data === 'object' ? data : { show: true }
      const { countryId = 209, type, url } = opts
      if (!type || !url) return
      this.countryId = +countryId
      monitorAddress({
        metric_name: 'web_customer_address_open_address_start_total',
        tags: {
          address_type: 'shopTransit',
          scene: this.pageType || window.SaPageInfo?.page_name || window.getSaPageInfo?.page_name,
          operate_type: type,
          country_id: String(this.countryId || '-'),
          result: '1',
          is_ssr: '0'
        }
      })
      nextTick(() => {
        if (this.countryId === 209) {
          this.$refs.storeTwVue.updateUI(opts)
        } else {
          this.$refs.storeFrVue.updateUI(opts)
        }
      })
    },
    submitClick (data) {
      this.$emit('save', data)
    },
    supplement(data) {
      this.$emit('supplement', data)
    },
    loading (type) {
      this.loadingFlag = type
    },
    resultsTipsShow (flag, text, time = 1500) {
      this.resultsShow = true
      this.resultsFlag = flag
      this.resultsText = text
      setTimeout(() => {
        this.resultsShow = false
      }, time)
    },
    universalToast(content) {
      SToast(content)
    },
    PrivacyHide () {
      this.privacyShow = false
    },
    closeStoreDialog() {
      if (this.countryId === 209) {
        this.$refs.storeTwVue?.clickCancel()
      } else {
        this.$refs.storeFrVue?.clickCancel()
      }
    },
    postSubmitData() {
      if (this.countryId === 209) {
        this.$refs.storeTwVue?.postSubmitData()
      } else {
        this.$refs.storeFrVue?.postSubmitData()
      }
    }
  },
})
</script>
<style lang="less">
  .address-tips {
    .sui-dialog__body {
      padding-bottom: .587rem;
      text-align: center;
    }
    .iconfont {
      .font-dpr(90px);
    }
  }
</style>

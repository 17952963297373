<template>
  <div class="address-certificate">
    <div
      v-enterkey
      class="address-item"
      :class="{ 'focus-value': type, 'check-false': error.type.show }"
      role="button"
      tabindex="0"
      :aria-label="language.SHEIN_KEY_PWA_34777"
      @click="onClickSelect"
    >
      <label
        class="item-title"
        for="address-identity"
      >{{ isRequired ? '*' : '' }}{{ language.SHEIN_KEY_PWA_34777 }}</label>
      <div class="item-input">
        <input
          id="address-identity"
          v-model="type"
          type="text"
          class="address-input j-address-certificate"
          :placeholder="language.SHEIN_KEY_PWA_34777"
          readonly
          tabindex="-1"
        /> <i class="suiiconfont sui_icon_nav_back_24px_mir"></i>
      </div>
      <p
        v-if="error.type.show"
        class="text-error"
        tabindex="0"
      >
        {{ language.SHEIN_KEY_PWA_34782 }}
      </p>
    </div>
    <input-vue
      :type="'nationalId'"
      :value="nationalId"
      :show="type === nationalIdLabel"
      :label="`${ isRequired ? '*' : '' }${ language.SHEIN_KEY_PWA_17824 || (language.label && language.label.national_id_tw) }`"
      :error-show="error.nationalId.show"
      :error-value="language.SHEIN_KEY_PWA_34783"
      :disabled="disabled"
      :language="language"
      @change="inputChange"
      @blur="inputBlur"
      @clear="clearInput"
    />
    <input-vue
      :type="'passportNumber'"
      :value="passportNumber"
      :show="type === passportNumberLabel"
      :label="`${ isRequired ? '*' : '' }${language.SHEIN_KEY_PWA_34781}`"
      :error-show="error.passportNumber.show"
      :error-value="language.SHEIN_KEY_PWA_34784"
      :disabled="disabled"
      :language="language"
      @change="inputChange"
      @blur="inputBlur"
      @clear="clearInput"
    />
  </div>
</template>

<script>
import { defineComponent, nextTick } from 'vue'
import { enterkey } from 'public/src/pages/common/ada/index.js'
import inputVue from '../input.vue'
export default defineComponent({
  components: {
    inputVue
  },
  directives: {
    enterkey,
  },
  emits: ['change', 'select'],
  props: {
    type: {
      type: String,
      default: ''
    },
    nationalIdLabel: {
      type: String,
      default: ''
    },
    passportNumberLabel: {
      type: String,
      default: ''
    },
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    disabled: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data () {
    return {
      nationalId: '',
      passportNumber: '',
      error: {
        type: {
          value: '',
          show: false,
          flag: false
        },
        nationalId: {
          value: '',
          show: false,
          flag: false
        },
        passportNumber: {
          value: '',
          show: false,
          flag: false
        }
      },
      AddressCheckRule: null
    }
  },
  computed: {
    isRequired() {
      return false
    }
  },
  methods: {
    async initData (opt = {}) {
      this.clearData()
      const { nationalId, passportNumber } = opt
      if (nationalId) {
        this.$emit('change', this.nationalIdLabel)
        this.nationalId = nationalId
        nextTick(() => {
          this.inputCheck('type')
          this.inputCheck('nationalId')
        })
      }
      if (passportNumber) {
        this.$emit('change', this.passportNumberLabel)
        this.passportNumber = passportNumber
        nextTick(() => {
          this.inputCheck('type')
          this.inputCheck('passportNumber')
        })
      }
    },
    clearData () {
      this.$emit('change', '')
      this.nationalId = ''
      this.passportNumber = ''
      this.oldPassportNumber = ''
    },
    getNationalId () {
      return this.type === this.nationalIdLabel ? this.nationalId : ''
    },
    getPassportNumber () {
      return this.type === this.passportNumberLabel ? this.passportNumber : ''
    },
    onClickSelect () {
      this.$emit('select')
    },
    clearInput (type) {
      this[type] = ''
    },
    inputBlur (type) {
      this.inputCheck(type)
    },
    inputCheck (type) {
      if (['nationalId', 'passportNumber'].includes(type) && this.AddressCheckRule) {
        return this.AddressCheckRule[type]({ value: this[type], instance: this })
      }
      let flag = true
      if (type === 'nationalId') {
        if (this.nationalId && !/^[A-Za-z]\d{9}$/.test(this.nationalId)) {
          flag = false
        }
      } else if (type === 'passportNumber') {
        if (this.passportNumber && (this.passportNumber.length < 2 || this.passportNumber.length > 30)) {
          flag = false
        }
      }
      this.error[type].show = !flag
      this.error[type].flag = flag
      return flag
    },
    inputChange (type, value) {
      this[type] = value
    },
    selectItem () {
      this.nationalId = ''
      this.passportNumber = ''
      nextTick(() => {
        this.inputCheck('type')
      })
    },
    checkBeforSubmit () {
      let flag = true
      if ([this.nationalIdLabel, this.passportNumberLabel].includes(this.type) && this.AddressCheckRule) {
        const type = (this.type === this.nationalIdLabel) ? 'nationalId' : 'passportNumber'
        return this.AddressCheckRule[type]({ value: this[type], instance: this })
      }
      if (!this.type) {
        flag = this.inputCheck('type')
      } else if (this.type === this.nationalIdLabel) {
        flag = this.inputCheck('nationalId')
      } else if (this.type === this.passportNumberLabel) {
        flag = this.inputCheck('passportNumber')
      }
      return flag
    }
  },
})
</script>

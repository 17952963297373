<template>
  <div
    v-if="show"
    class="address-item"
    :class="['j-adr-' + type + '-con', (value || focusFlag) ? 'focus-value' : '', errorShow ? 'check-false' : '',noGap ? 'store-item__nogap' : '']"
  >
    <label
      :id="'j-adr-label-' + type"
      class="item-title"
      @click="itemFocus"
    >
      <span
        :class="{ 'disable-gray': isDisabled }"
        v-html="label"
      ></span>
      <sui_icon_doubt_12px_2
        v-if="isShowHint"
        class="title-hint"
        size="12px"
        color="#959595"
        @click.stop="toggleShowHint"
      />
      <i
        v-if="isDisabled"
        class="iconfont icon-pro"
        @click.stop="prompt"
      ></i>
      <i
        v-if="hasHelp"
        class="iconfont icon-pro"
        @click.stop.prevent="onClickHelp"
      ></i>
    </label>
    <div
      class="item-input"
      :class="{'no-border': noBorder, 'disable-gray': isDisabled }"
    >
      <span
        v-if="areaCode.length || (telEn || telNum)"
        class="new_tel_num"
      >
        <template v-if="areaCode.length">
          <select v-model="telCode">
            <option
              v-for="(code, index) in areaCode"
              :key="code"
              :value="index"
            >
              {{ telEn }} +{{ code }}
            </option>
          </select>
          <i class="iconfont icon-down"></i>
        </template>
        <template v-else-if="telEn || telNum">
          {{ telEn }} {{ telNum }}
        </template>
      </span>
      <div
        class="input-area"
        @click="itemFocus"
      >
        <input
          :ref="type"
          v-model.trim="inputValue"
          :aria-labelledby="'j-adr-label-' + type"
          :type="inputType"
          class="address-input"
          autocomplete="new-password"
          :class="'j-address-' + type"
          :disabled="disabled.includes(type)"
          :maxlength="maxLength"
          placeholder=""
          @input="inputChange" 
          @focus="inputFocus" 
          @blur="inputBlur"
        />
        <input
          v-show="false"
          type="password"
          autocomplete="new-password"
          tabindex="-1"
        />
        <i
          v-if="value && focusFlag"
          class="iconfont icon-clear1"
          @click.stop.prevent="clearInput"
        ></i>
        <i
          v-else-if="hasList"
          class="suiiconfont sui_icon_more_down_16px"
          @click.stop="onClickLeft"
        ></i>
        <p class="text-placeholer">
          <span
            class="title"
            v-html="label"
          ></span>
          <sui_icon_doubt_12px_2
            v-if="isShowHint"
            class="title-hint"
            size="12px"
            color="#959595"
            @click.stop="toggleShowHint"
          />
          <i
            v-if="disabled.includes(type)"
            class="iconfont icon-pro"
            @click.stop="prompt"
          ></i>
          <i
            v-if="hasHelp"
            class="iconfont icon-pro"
            @click.stop.prevent="onClickHelp"
          ></i>
          <span
            class="gray-text"
            v-html="placeholder"
          ></span>
        </p>
      </div>
    </div>
    <p
      v-if="errorShow"
      class="text-error"
      tabindex="0"
      v-html="errorValue"
    ></p>
    <p
      v-if="sensitiveNoteText"
      class="sensitive-color"
      tabindex="0"
      v-html="sensitiveNoteText"
    ></p>
    <p
      v-if="grayText"
      class="text-error gray-color"
      tabindex="0"
      v-html="grayText"
    ></p>
    <p
      v-if="isShowPopoverTips"
      class="c-adr-tip"
      tabindex="-1"
      v-html="popoverTips"
    ></p>
    <slot></slot>
    <SDialog
      v-model:visible="isShowHintDialog"
      class="hint"
      :close-on-click-modal="false"
      append-to-body
    >
      <template
        v-if="hint.title"
        #title
      >
        {{ hint.title }}
      </template>
      <template v-if="hint.img">
        <img
          v-if="lazyImg"
          :src="lazyImg"
          :data-src="hint.img"
          class="lazyload hint__img"
          width="100%"
          height="auto"
          alt=""
        />
        <img
          v-else
          :src="hint.img"
          class="hint__img"
          width="100%"
          height="auto"
          alt=""
        />
      </template>
      <h5
        v-if="hint.subTitle"
        class="hint__sub-title"
        v-html="hint.subTitle"
      ></h5>
      <p
        v-if="hint.content"
        class="hint__content"
        v-html="hint.content"
      ></p>
      <p
        v-if="hint.statement"
        class="hint__statement"
        v-html="hint.statement"
      ></p>
      <template #footer>
        <SButton
          class="hint__btn"
          :type="['primary', 'H72PX']"
          @click="toggleShowHint"
        >
          {{ language?.SHEIN_KEY_PWA_15146 || '' }}
        </SButton>
      </template>
    </SDialog>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { SToast } from '@shein-aidc/sui-toast/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { sui_icon_doubt_12px_2 } from '@shein-aidc/icon-vue3'

export default defineComponent({
  name: 'SelectUpper',
  components: {
    SButton,
    SDialog,
    sui_icon_doubt_12px_2,
  },
  emits: ['prompt', 'focus', 'blur', 'change', 'clear', 'more', 'help'],
  props: {
    language: {
      type: Object,
      default () {
        return {}
      }
    },
    noGap: {
      type: Boolean,
      default: false
    },
    noBorder: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      default: ''
    },
    inputType: {
      type: String,
      default: 'text'
    },
    show: {
      type: Boolean,
      default: false
    },
    value: {
      type: String,
      default: ''
    },
    errorShow: {
      type: Boolean,
      default: false
    },
    errorValue: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    telEn: {
      type: String,
      default: ''
    },
    telNum: {
      type: String,
      default: ''
    },
    hasList: {
      type: Boolean,
      default: false
    },
    hasHelp: {
      type: Boolean,
      default: false
    },
    maxLength: {
      type: Number,
      default: 1000
    },
    areaCode: {
      type: Array,
      default() {
        return []
      }
    },
    disabled: {
      type: Array,
      default() {
        return []
      }
    },
    bubbleTips: {
      type: String,
      default: ''
    },
    isShowExceed: {
      type: Boolean,
      default: false
    },
    sensitiveNote: {
      type: String,
      default: '',
    },
    isShowHint: {
      type: Boolean,
      default: false,
    },
  },
  data () {
    return {
      focusFlag: false,
      inputValue: '',
      telCode: 0,
      isShowHintDialog: false,
      lazyImg: '/pwa_dist/images/bg-logo-5ab25d7379.png',
    }
  },
  computed: {
    isDisabled() {
      return this.disabled.includes(this.type)
    },
    popoverTips() {
      return this.bubbleTips
    },
    isShowPopoverTips () {
      return this.isShowExceed && this.bubbleTips
    },
    grayText () {
      return this.text
    },
    sensitiveNoteText () {
      return this.sensitiveNote
    },
    hint() {
      return {
        title: this.language?.SHEIN_KEY_PWA_21831 || '',
        img: '//img.ltwebstatic.com/images3_ccc/2025/01/07/2d/173623160140170bb30a7a398e4ce146d5a9ffd028.png',
        subTitle: this.language?.SHEIN_KEY_PWA_21832 || '',
        content: this.language?.SHEIN_KEY_PWA_21695 || '',
        statement: this.language?.SHEIN_KEY_PWA_21694 || '',
      }
    },
  },
  watch: {
    value () {
      this.inputValue = this.value
    }
  },
  mounted () {
    this.inputValue = this.value
  },
  methods: {
    toggleShowHint() {
      this.isShowHintDialog = !this.isShowHintDialog
    },
    prompt() {
      SToast(this.language.SHEIN_KEY_PWA_18259)
    },
    itemFocus () {
      this.$refs[this.type] && this.$refs[this.type].focus()
    },
    inputFocus () {
      this.focusFlag = true
      this.$emit('focus', this.type)
    },
    inputBlur () {
      setTimeout(() => {
        this.focusFlag = false
        this.$refs[this.type] && this.$refs[this.type].blur()
      }, 20)
      this.$emit('blur', this.type)
    },
    inputChange () {
      const regex = /\p{Emoji_Modifier_Base}\p{Emoji_Modifier}?|\p{Emoji_Presentation}|\p{Emoji}\uFE0F/gu
      if (['fname', 'middleName', 'lname', 'address1', 'address2'].indexOf(this.type) >= 0 && regex.test(this.inputValue)) {
        this.inputValue = this.inputValue.replace(regex, '').replace(/(?:\u200D)/g, '')
      }
      this.$emit('change', this.type, this.inputValue)
    },
    clearInput () {
      this.$emit('clear', this.type)
      setTimeout(() => {
        this.itemFocus()
      }, 30)
    },
    onClickLeft () {
      this.$emit('more', this.type)
    },
    onClickHelp () {
      this.$emit('help', this.type)
    }
  },
})
</script>
<style lang="less" scope>
 .address-item {
  .sensitive-color {
    padding: 0.10666rem 0 0.32rem;
    color: @sui_color_micro_emphasis;
    font-size: 12px;
  }
}
.store-item__nogap {
  margin-bottom: 0.32rem;
  /* stylelint-disable-next-line declaration-no-important */
  margin-top: 0 !important;
}
.title-hint {
  margin-left: 8 / 75rem;
}
.hint {
  &__sub-title,
  &__content,
  &__statement {
    margin-top: 0.2rem;
    font-size: 12px;
  }
  &__img {
    margin-top: 0.2rem;
  }
  &__sub-title {
    text-align: center;
  }
  &__statement {
    font-weight: bold;
  }
  &__btn {
    width: 100%;
  }
}
</style>

<template>
  <div class="c-tab">
    <ul class="c-tab__container">
      <li
        v-for="tab in tabs"
        :key="tab.id"
        :class="['item', { selected: selectedValue === tab.id }]"
        @click.stop="toShowUrge({ id: tab.id })"
      >
        <span class="text">{{ tab.text }}</span>
      </li>
    </ul>

    <SDialog
      v-model:visible="isShowUrge"
      :close-on-click-modal="false"
      append-to-body
      show-close
      @close="toggleShowUrge({ type: 'no' })"
    >
      {{ language?.SHEIN_KEY_PWA_25075 || '' }}
      <template #footer>
        <SButtonGroup width="100%">
          <SButtonGroupItem
            :type="['primary', 'H72PX']"
            @click.stop="toggleShowUrge({ type: 'yes' })"
          >
            {{ language?.SHEIN_KEY_PWA_25082 || '' }}
          </SButtonGroupItem>
          <SButtonGroupItem
            :type="['H72PX']"
            @click.stop="toggleShowUrge({ type: 'no' })"
          >
            {{ language?.SHEIN_KEY_PWA_15106 || '' }}
          </SButtonGroupItem>
        </SButtonGroup>
      </template>
    </SDialog>
  </div>
</template>
<script setup>
import { ref, toRefs, defineEmits, defineProps, computed } from 'vue'
import { SDialog } from '@shein-aidc/sui-dialog/mobile'
import { SButtonGroup } from '@shein-aidc/sui-button-group/mobile'
import { SButtonGroupItem } from '@shein-aidc/sui-button-group-item/mobile'

const props = defineProps({
  calendarType: {
    type: Number,
    default: 1,
  },
  idExpireDate: {
    type: String,
    default: '',
  },
  birthday: {
    type: String,
    default: '',
  },
  language: {
    type: Object,
    default: () => ({}),
  },
})

const { calendarType, idExpireDate, birthday, language } = toRefs(props)
const emits = defineEmits([
  'update:calendarType',
  'update:idExpireDate',
  'update:birthday',
])
const selectedValue = computed(() => {
  return calendarType.value || 2
})
const isShowUrge = ref(false)

const tabs = computed(() => {
  return [
    {
      text: language.value?.SHEIN_KEY_PWA_25069 || '',
      id: 1,
    },
    {
      text: language.value?.SHEIN_KEY_PWA_25070 || '',
      id: 2,
    },
  ]
})
const resetCalendar = () => {
  emits('update:calendarType', selectedValue.value == 2 ? 1 : 2)
  emits('update:idExpireDate', '')
  emits('update:birthday', '')
}

const toShowUrge = ({ id = 1 } = {}) => {
  if (selectedValue.value == id) {
    return
  }
  const hasSetCalendar = idExpireDate.value || birthday.value
  if (hasSetCalendar) {
    isShowUrge.value = true
  } else {
    resetCalendar({ id })
  }
}

function toggleShowUrge({ type = 'no' } = {}) {
  if (type === 'yes') {
    resetCalendar()
  }
  isShowUrge.value = false
}
</script>
<style lang="less" scoped>
.c-tab {
  padding: 24 / 75rem 1.53rem 16 / 75rem 1.53rem;
  background: @sui_color_white;
  &__container {
    display: flex;
    padding: 4 / 75rem;
    background: @sui_color_gray_weak2;
  }
  .item {
    flex: 1;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 6 / 75rem 20 / 75rem;
    text-align: center;
    color: @sui_color_gray_dark3;
    font-size: 12px;
    line-height: 1.15;
    &.selected {
      color: @sui_color_gray_dark1;
      font-weight: 700;
      background: @sui_color_white;
      border: 0.5px solid rgba(0, 0, 0, 0.04);
      box-shadow: 0px 3px 8px 0px rgba(0, 0, 0, 0.12),
        0px 3px 1px 0px rgba(0, 0, 0, 0.04);
    }
  }
}
</style>
